import React from "react";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

import SEO from "../components/seo";

import Layout from "@components/Layout";
import Anchor from "@components/Anchor";
import ServiceHero from "@sections/service/Service.Hero";
import ServiceFeatures from "@sections/service/Service.Features";
import ServiceTechnologies from "@sections/service/Service.Technologies";
import ServiceServices from "@sections/service/Service.Services";
import ServiceCTA from "@sections/service/Service.CTA";
// import ServiceStrategy from "@sections/service/Service.Strategy";

import "../components/Utils";

import { useThemeUI } from 'theme-ui';

// import Icons from '@icons';
import PersonalWebsite from '@icons/undraw/PersonalWebsite.Icon';
import Heatmap from '@icons/undraw/Heatmap.Icon';
import SpeedTest from '@icons/undraw/SpeedTest.Icon';
import ExperienceDesign from '@icons/undraw/ExperienceDesign.Icon';
import DarkAnalytics from '@icons/undraw/DarkAnalytics.Icon';
import VisualData from '@icons/undraw/VisualData.Icon';
import Segmentation from '@icons/undraw/Segmentation.Icon';

const WebDevelopmentPage = () => {
    const intl = useIntl();
    const invertHeader = true;

    const context = useThemeUI();
    const { theme } = context;
    const fillColor = theme.colors.gradientBg;



    const HeroTranslations = {
        title: {
            text: intl.formatMessage({ id: 'web-title' })
        },
        subtitle: {
            text: intl.formatMessage({ id: 'web-subtitle' })
        },
        description: {
            text: intl.formatMessage({ id: 'web-description' })
        },
        Link1: {
            text: intl.formatMessage({ id: 'web-button-discover' }),
            link: '/web-development-services/#work'
        },
        Link2: {
            text: intl.formatMessage({ id: 'web-button-contact' }),
            link: '/contact/'
        },
        MainIcon: {
            Icon: PersonalWebsite,
            fill: fillColor,
        }
    };

    const FeatureTranslations = {
        title: {
            text: intl.formatMessage({ id: 'web-features-title' })
        },
        subtitle: {
            text: intl.formatMessage({ id: 'web-features-subtitle' })
        },
        features: [
            {
                title: intl.formatMessage({ id: 'web-features-1' }),
                description: intl.formatMessage({ id: 'web-features-1-desc' }),
                Icon: Heatmap,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'web-features-2' }),
                description: intl.formatMessage({ id: 'web-features-2-desc' }),
                Icon: SpeedTest,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'web-features-3' }),
                description: intl.formatMessage({ id: 'web-features-3-desc' }),
                Icon: ExperienceDesign,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'web-features-4' }),
                description: intl.formatMessage({ id: 'web-features-4-desc' }),
                Icon: DarkAnalytics,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'web-features-5' }),
                description: intl.formatMessage({ id: 'web-features-5-desc' }),
                Icon: VisualData,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'web-features-6' }),
                description: intl.formatMessage({ id: 'web-features-6-desc' }),
                Icon: Segmentation,
                fill: fillColor
            },
        ],
    };

    const ServicesTranslations = {
        title: {
            text: intl.formatMessage({ id: 'web-services-title' })
        },
        typeformButton: {
            text: intl.formatMessage({ id: 'web-services-button' }),
        },
        services: [
            { 
                title: { text: intl.formatMessage({ id: 'web-services-1' }) },
                description: { text: <FormattedMessage id="web-services-1-desc" values={{ br: <br /> }} />},
                Image: { name: 'website-development.jpg' },
            },
            { 
                title: { text: intl.formatMessage({ id: 'web-services-2' }) },
                description: { text: intl.formatMessage({ id: 'web-services-2-desc' }) },
                Image: { name: 'ecommerce-shop.jpg' },
            },
            { 
                title: { text: intl.formatMessage({ id: 'web-services-3' }) },
                description: { text: <FormattedMessage id="web-services-3-desc" values={{
                    googlePWALink: (
                        <Anchor href={`https://developers.google.com/web/showcase/2016/aliexpress`} target={`_blank`} rel={`noopener noreferrer`}>
                            <FormattedMessage id="web-services-3-link" />
                        </Anchor>
                    )
                }} />},
                Image: { name: 'pwa-application.jpg' },
            },
        ]
    };

    return (
        <Layout id="web" invertHeader={invertHeader}>
            <SEO
                /*lang={intl.locale}*/
                lang={intl.formatMessage({ id: 'current' })}
                title={intl.formatMessage({ id: 'web-meta-title' })}
                description={intl.formatMessage({ id: 'web-meta-description'})}
                slug='/web-development-services/'
            />
            <ServiceHero translations={ HeroTranslations } />
            <ServiceFeatures translations={ FeatureTranslations } />
            <ServiceTechnologies sectionContext={{ noPaddingTop: true, }} />
            <ServiceServices translations={ ServicesTranslations } sectionContext={{ small: true, noPaddingTop: true, noPaddingBottom: true, }} />
            <ServiceCTA sectionContext={{ small: true }} />
            {/*<ServiceStrategy sectionContext={{ gradientBg: true, }}/>*/}
        </Layout>
    );
};

export default WebDevelopmentPage;
