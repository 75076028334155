import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import HeadingsContainer from '@components/Headings/Headings.Container';
import Grid from '@components/Grid';
import Button from '@components/Button';
import Anchor from '@components/Anchor';
import CardDefault from '@components/Card/Card.Default';
import CardImageContainer from '@components/Card/Card.Image';
// import CardBorder from '@components/Card/Card.Border';
import CardContent from '@components/Card/Card.Content';

import TranslatedSectionTemplate from '@types';

import Image from '../../components/Image';
import Typeform from '../../components/Typeform';


const ServiceServices: TranslatedSectionTemplate = ({sectionContext, translations, children}) => {
    const intl = useIntl();

    const contactUrl = intl.formatMessage({ id: 'typeform-contact-url'});

    return (
        <Transitions.CSS.FadeIn>
            <Section { ...sectionContext }>
                <Wrapper>
                    <HeadingsContainer center>
                        <MainHeading>{ translations.title.text }</MainHeading>
                        { translations.subtitle &&
                        <Paragraph center lead>{ translations.subtitle.text }</Paragraph>
                        }
                    </HeadingsContainer>

                    <StyledGrid columns="1">
                    {translations.services.map((service, i) => {
                        const illustration = service.Icon ? true : false;
                        const Illustration = service.Icon ? styled(service.Icon)`
                            width: 100%;
                            height: 100%;
                            max-height: 150px;
                        ` : null;
                        const left = i % 2 ? false : true;

                        return (
                        <ModifiedCardDefault left={ left }>
                            <StyledCardImageContainer className="image-container">
                                { illustration ?
                                <Illustration />
                                :
                                <CardImage imgName={ service.Image.name } />
                                }
                            </StyledCardImageContainer>
                            <StyledCardContent>
                                <CardHeader>{ service.title.text }</CardHeader>
                                <CardParagraph>{ service.description.text }</CardParagraph>
                                <Typeform url={contactUrl} styles={{marginTop: '20px'}} style={{ display: 'none' }}>{ translations.typeformButton.text}</Typeform>
                            </StyledCardContent>
                        </ModifiedCardDefault>
                        );
                    })}
                    </StyledGrid>
                </Wrapper>
            </Section>
        </Transitions.CSS.FadeIn>
    );
};

const MainHeading = styled(Headings.h2)`
    margin-bottom: 40px;
`;

// ==========================================================
// Single Service Card Grid and Styles
// ==========================================================
const CardHeader = styled(Headings.h3)`
    // margin-bottom: 0px;
    text-align: left;
    font-weight: bold;
`;

const CardParagraph = styled(Paragraph)`
    margin-top: 10px;
    margin-bottom: 0px !important;
    font-size: 16px;
    text-align: left;
`;

const CardButton = styled(Button)`
    margin-top: 20px;
    // Or have it like this - centered as a block
    ${mediaqueries.tablet_ipad`
        display: block;
        margin 20px auto;
    `};
`;

const CardImage = styled(Image)`
    border-radius: 0.25em;
`;

const StyledCardImageContainer = styled(CardImageContainer)`
    // Center the SVG in the top grid layout
    display: flex;
    align-items: center;
    // justify-content: left;
    justify-content: space-between;

    ${mediaqueries.from_tablet_ipad`
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    `};
`;

const StyledCardContent = styled(CardContent)`
    padding: 5% 5% !important;

    ${mediaqueries.from_laptop`
        padding: 5% 0% !important;
    `}
`;

// ==========================================================
// Big Wrapper Grid
// ==========================================================
const StyledGrid = styled(Grid)`
    // margin-top: 50px;
    
    ${mediaqueries.tablet_ipad`
        grid-template-columns: 1fr;
    `};

    ${mediaqueries.from_tablet_ipad`
        overflow: visible;
        grid-row-gap: 50px;
    `}; 

    ${mediaqueries.laptop`
        grid-template-columns: 1fr;
        max-width: 550px;
    `}; 

    ${mediaqueries.from_laptop`
        grid-template-columns: 1fr;
        grid-row-gap: 60px;
    `};
`;

const ModifiedCardDefault = styled(CardDefault)<{ left?: boolean }>`
    display: grid;
    // background: transparent;

    ${mediaqueries.from_tablet_ipad`
        align-items: center;
        box-shadow: none;
        overflow: visible;
        & .image-container {
            transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
        }
        &:hover .image-container, &:focus .image-container {
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            box-shadow: 0 1px 2px rgba(0,0,0,0.05), 0 15px 30px rgba(0,0,0,0.1);
        }
    `};

    ${mediaqueries.from_laptop`
        grid-column-gap: 4%;
        grid-template-columns: 2fr 3fr;

    `};

    ${mediaqueries.laptop`
        // grid-column-gap: 2%;
        grid-template-columns: 1fr;
    `};

    ${mediaqueries.tablet_ipad`
        grid-template-columns: 1fr;
    `};

    ${mediaqueries.tablet`
        grid-template-columns: 1fr;
    `};

    ${p => p.left && mediaqueries.from_laptop`
        grid-template-columns: 3fr 2fr;
    
        & div:last-child {
          order:-1;
        }
    `};
`;

export default ServiceServices;