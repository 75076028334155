import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import HeadingsContainer from '@components/Headings/Headings.Container';
import Grid from '@components/Grid';
import CardDefault from '@components/Card/Card.Default';
import CardImageContainer from '@components/Card/Card.Image';
// import CardBorder from '@components/Card/Card.Border';
import CardContent from '@components/Card/Card.Content';
import Wave from '@components/Wave';

import TranslatedSectionTemplate from '@types';

const ServiceFeatures: TranslatedSectionTemplate = ({ sectionContext, translations }) => {
    const intl = useIntl();

    const columns = translations.columns || 3;
    const wave = 'wave' in translations ? translations.wave : true;

    return (
        <Transitions.CSS.FadeIn>
            <Section id="work" {...sectionContext}>
            
                { wave ? <Wave top waveContext={{ gradientBg: true }} /> : ''}
                <Wrapper>
                    <HeadingsContainer center>
                        <Headings.h2>{translations.title.text}</Headings.h2>
                        <Paragraph center lead>{translations.subtitle.text}</Paragraph>
                    </HeadingsContainer>
                    <StyledGrid columns={ columns }>
                    {translations.features.map(feature => {
                        const Icon = feature.Icon;
                        return (
                            <ModifiedCardDefault /*hover animateImage animateBorder*/ flexStartAlign>
                                <StyledCardImageContainer flexible>
                                    <Icon fill={feature.fill ? feature.fill : '#c4c4c4'} />
                                </StyledCardImageContainer>
                                <CardContent>
                                    <CardHeader>{ feature.title }</CardHeader>
                                    <CardParagraph>{ feature.description }</CardParagraph>
                                </CardContent>
                            </ModifiedCardDefault>
                        );
                    })}
                    </StyledGrid>
                </Wrapper>
            </Section>
        </Transitions.CSS.FadeIn>
    );
};

const CardHeader = styled(Headings.h4)`
    // margin-bottom: 0px;
    text-align: center;
    font-weight: bold;
`;

const CardParagraph = styled(Paragraph)`
    margin-top: 10px;
    margin-bottom: 0px !important;
    font-size: 16px;
    text-align: center;
`;

// const CardButton = styled(Link)`
//     margin-top: 20px;
//     // display: block;
//     margin 20px auto;
// `;

const StyledCardImageContainer = styled(CardImageContainer)`
    // Center the SVG in the top grid layout
    display: flex;
    align-items: center;
    justify-content: left;
`;

const StyledGrid = styled(Grid)`
    margin-top: 50px;

    ${mediaqueries.desktop`
        grid-template-columns: repeat(2, 1fr);
    `};   

    ${mediaqueries.tablet_ipad`
        grid-template-columns: 1fr;
        max-width: 550px;
    `};

    ${mediaqueries.from_laptop`
        // grid-column-gap: 2%;
    `};
`;

const ModifiedCardDefault = styled(CardDefault)`
    align-items: center;
    box-shadow: none;
    // background: transparent;

    ${mediaqueries.from_desktop`
        // grid-column-gap: 2%;
    `};

    ${mediaqueries.laptop`
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 2%;
    `};

    ${mediaqueries.tablet_ipad`
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-column-gap: 2%;
    `};

    ${mediaqueries.tablet`
        grid-template-columns: 1fr;
    `};
`;

export default ServiceFeatures;