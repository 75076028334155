import React from "react";

import { Icon } from '@types';

const SpeedTestIcon: Icon = ({ fill = '#c4c4c4', title = 'speed test', ...props }) => (
<svg { ...props } id="e9543dae-de41-418f-9290-24abb7460ee9" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="1012" height="794" viewBox="0 0 1012 794">
    <title>{ title }</title>
    <path d="M423.3103,249.13721,303.3808,348.5957a54.31348,54.31348,0,1,1-83.525,69.26789L112.33411,507.03217,284.6897,714.86279l310.97619-257.895Z" transform="translate(-94 -53)" fill="#f2f2f2" />
    <path d="M1106,550.26179c0,122.83942-73.02609,165.73036-163.10833,165.73036S779.78334,673.10121,779.78334,550.26179,942.89167,271.15124,942.89167,271.15124,1106,427.42236,1106,550.26179Z" transform="translate(-94 -53)" fill="#f2f2f2" />
    <polygon points="842.95 644.201 844.62 541.396 914.141 414.211 844.882 525.269 845.633 479.043 893.547 387.026 845.832 466.81 845.832 466.811 847.182 383.672 898.489 310.415 847.395 370.598 848.239 218.151 842.935 419.964 843.371 411.638 791.207 331.793 842.535 427.62 837.674 520.472 837.53 518.007 777.394 433.981 837.347 526.714 836.74 538.326 836.631 538.5 836.68 539.453 824.349 775.026 840.825 775.026 842.801 653.348 902.608 560.843 842.95 644.201" fill="#3f3d56" />
    <ellipse cx="707" cy="777" rx="228" ry="17" fill="#3f3d56" />
    <rect x="692" width="36" height="782" fill="#3f3d56" />
    <path d="M1008.47332,802.11115c0,22.46856-13.35644,30.31375-29.83388,30.31375q-.57257,0-1.14331-.01273c-.76345-.01636-1.51962-.05271-2.26669-.10361-14.87057-1.05244-26.42387-9.30115-26.42387-30.19741,0-21.62514,27.63446-48.91242,29.71026-50.93189l.00364-.00363c.08-.07816.12-.11633.12-.11633S1008.47332,779.64259,1008.47332,802.11115Z" transform="translate(-94 -53)" fill={ fill } />
    <path d="M977.55246,828.98762l10.91163-15.24683-10.9389,16.92094-.02906,1.75044c-.76345-.01636-1.51962-.05271-2.26669-.10361l1.17607-22.47946-.0091-.1745.02-.03272.11088-2.12307-10.96619-16.96276L976.5618,805.9065l.02548.45079.88883-16.98456-9.38839-17.52807,9.50291,14.547.9252-35.21244.00364-.12v.11633l-.1545,27.76715,9.34661-11.008-9.38478,13.40006-.2472,15.20685,8.72677-14.59429-8.76312,16.83189-.13814,8.4541,12.66934-20.31277-12.7166,23.2629Z" transform="translate(-94 -53)" fill="#3f3d56" />
    <path d="M743.22676,785.29014l-6.3818-4.74121s-4.22435,23.59659.48949,27.132,22.39076,9.42769,22.39076,16.49846,40.06768,16.49845,41.24614,5.8923-3.53538-16.49845-3.53538-16.49845-18.85538-18.85538-21.2123-22.39076-8.24923-10.60615-8.24923-10.60615Z" transform="translate(-94 -53)" fill="#2f2e41" />
    <path d="M837.50364,491.85334s-.17679,1.03705-.48306,2.93436c-.283,1.68519-.6718,4.0657-1.14322,7.00006-4.94962,30.204-19.38577,119.11886-18.40755,122.05322,1.17846,3.53538,9.42768,16.49845,0,20.03384-.90744.34172-1.88551,1.44948-2.93436,3.19358a68.49432,68.49432,0,0,0-4.98487,11.76109c-5.62128,16.30986-11.63155,42.4128-15.10795,60.49035-1.52026,7.94289-2.55717,14.33013-2.86358,17.67692a9.61182,9.61182,0,0,0-.03539,2.3334c1.17846,4.71385-7.07076,21.2123-10.60615,25.92615s-9.42769,9.42768-8.24922,14.14153-29.46153,17.67691-32.99691,9.42769-3.53539-12.96307-4.71385-15.32-2.35692-18.85537,3.53538-21.2123,2.35693-43.603,8.24923-51.85228c1.96794-2.74584,4.97307-12.04391,8.11962-23.2275,1.23729-4.38384,2.48653-9.05057,3.71218-13.729,4.97307-19.17359,9.3805-38.465,9.3805-38.465L766.796,487.1395l64.81536-8.24923,1.66153,3.665,3.31154,7.27109Z" transform="translate(-94 -53)" fill="#2f2e41" />
    <path d="M841.039,718.11786s9.42769-9.42768,14.14153,0,9.42769,45.96,17.67692,54.20921,18.85537,49.49537,7.07076,51.85229-24.74768-3.53538-25.92614-10.60615S828.076,769.97015,828.076,766.43477s16.49845-16.49846,16.49845-16.49846Z" transform="translate(-94 -53)" fill="#2f2e41" />
    <path d="M696.08832,427.038s15.32,137.87995,18.85537,150.843,22.39076,62.45843,28.28307,70.70766,43.60306,75.42151,48.3169,78.95689,14.14154,12.96307,14.14154,12.96307,17.67691,17.67692,20.03383,21.2123,22.39076-7.07076,23.56922-14.14153-1.17846-18.85538-2.35692-23.56922,2.35692-11.78461-1.17846-15.32-21.2123-21.2123-21.2123-29.46153-30.64-56.56613-42.4246-67.17228-9.42768-28.28307-9.42768-28.28307V511.88718l64.81535-20.03384s-3.53538-56.56613-11.78461-56.56613S709.05139,416.43183,696.08832,427.038Z" transform="translate(-94 -53)" fill="#2f2e41" />
    <circle cx="697.54366" cy="138.34577" r="38.88922" fill="#9f616a" />
    <path d="M778.58059,221.98576s-4.71384,17.67691-10.60615,22.39076,15.32,24.74768,15.32,24.74768l40.06767,2.35692,7.07077-22.39076s-12.96307-25.92614-9.42769-42.4246S778.58059,221.98576,778.58059,221.98576Z" transform="translate(-94 -53)" fill="#9f616a" />
    <path d="M762.08214,242.01959s10.60615,1.17847,12.96307,4.71385,18.85537,14.14153,31.81845,11.78461,21.2123-5.89231,23.56922-4.71384,7.07076,25.92614,7.07076,25.92614L824.54057,317.4411l2.35692,103.70458,3.53539,28.28306s7.07076-9.42769-21.2123-3.53538-64.81536-4.71385-76.6-5.89231-31.81845-4.71384-32.99691-11.78461,3.53538-32.99691,3.53538-32.99691l18.85538-78.95689,11.78461-42.4246Z" transform="translate(-94 -53)" fill="#3f3d56" />
    <path d="M773.22908,236.70072s-9.96848-5.28728-13.50387-.57343-48.3169,11.78461-48.3169,15.32-9.42769,98.99073-7.07077,107.24-4.71384,17.67692-4.71384,17.67692L691.37447,411.718s-22.39076,67.17228-12.96307,69.5292,17.67692,0,21.2123,0,2.35692-44.78152,11.78461-56.56613,22.39076-41.24614,24.74768-47.13844,31.81845-87.20612,30.64-96.63381S773.22908,236.70072,773.22908,236.70072Z" transform="translate(-94 -53)" fill="#d0cde1" />
    <path d="M822.18365,242.01959s9.42769,0,10.60615,2.35693,12.96307,17.67691,17.67692,21.2123S871.679,284.44419,871.679,287.97958s-34.17537,29.46152-34.17537,29.46152-14.14153,69.52921-7.07076,93.09843,17.67691,80.13535,12.96307,83.67073-15.32,4.71385-15.32,0-7.07077-55.38767-12.96308-65.99382-23.56922-77.77843-16.49845-96.6338S822.18365,242.01959,822.18365,242.01959Z" transform="translate(-94 -53)" fill="#d0cde1" />
    <path d="M666.62679,358.68724s-1.17846,36.53229,11.78461,55.38767,21.2123,40.06768,21.2123,40.06768,21.2123-5.89231,24.74768-12.96308,25.92615-1.17846,25.92615-3.53538S723.19292,423.5026,720.836,421.14568s-10.60615-2.35693-15.32-7.07077-5.89231-15.32-5.89231-15.32l-4.71384-30.64Z" transform="translate(-94 -53)" fill="#9f616a" />
    <path d="M764.75883,167.36749c-2.9918-.27176-6.07737-.57079-8.72149-1.99675-5.92458-3.19509-7.49563-12.29387-2.98552-17.29068a22.344,22.344,0,0,1,4.14313-3.33142l8.95783-6.09718c3.96186-2.69664,8.01737-5.43748,12.6382-6.70871,4.17944-1.1498,8.59364-1.03165,12.92-.76273,8.39862.522,16.84923,1.60028,24.74865,4.5s15.2682,7.75089,20.05246,14.67333c7.58453,10.9742,7.68878,25.77486,3.51145,38.444s-12.11238,23.70139-19.9311,34.51l-4.05692-19.7948c-.36135-1.76309-.82864-3.69543-2.295-4.7389-3.64454-2.59347-8.10267,2.73144-12.57565,2.69692-2.87408-.02218-5.28706-2.38612-6.37625-5.04591s-1.1609-5.60529-1.4129-8.46838c-.53374-6.0642-2.65335-14.50206-8.39619-17.84651C779.62812,166.99331,770.774,167.91387,764.75883,167.36749Z" transform="translate(-94 -53)" fill="#2f2e41" />
    <path d="M766.9404,492.80446s3.98019,115.12729,6.33712,118.66268,0-119.02457,0-119.02457Z" transform="translate(-94 -53)" opacity="0.1" />
    <path d="M720.836,251.44728h-9.42769s-40.06768,54.20921-35.35383,57.74459,0,12.96308,0,12.96308l-9.42769,17.67691s-11.78461,10.60615-8.24923,12.96307,8.24923,11.78461,8.24923,11.78461,23.56922-3.53538,30.64,10.60615l15.28442-19.29563Z" transform="translate(-94 -53)" fill="#d0cde1" />
    <path d="M828.076,352.79493s-25.92615,1.17847-55.38767-17.67691L743.22676,322.155s-25.92614-40.06768-28.28307-32.99691,3.53539,21.2123,3.53539,21.2123S682.92423,326.82515,682,329s19.98062-3.30967,19.98062-3.30967-3.53538,22.39076,16.49846,23.56922,25.92614-3.53538,25.92614-3.53538,84.8492,70.70766,98.99073,48.3169S828.076,352.79493,828.076,352.79493Z" transform="translate(-94 -53)" fill="#9f616a" />
    <path d="M858.71594,283.26573l12.96307,4.71385v35.35383l4.71385,7.07076s-1.17846,14.14154-4.71385,17.67692-10.60614,2.35692-8.24922,10.60615a44.667,44.667,0,0,1,1.17846,16.49845s-21.2123,27.10461-22.39076,23.56923-17.67692-47.13845-20.03384-47.13845,23.56922-68.35074,23.56922-68.35074Z" transform="translate(-94 -53)" fill="#d0cde1" />
    <path d="M703.74831,461.80258s-12.185-11.78461-6.09251-12.37384,27.30481-12.37384,30.8402-6.48153S703.74831,461.80258,703.74831,461.80258Z" transform="translate(-94 -53)" fill="#2f2e41" />
    <path d="M517.71667,808A226.92219,226.92219,0,0,0,550,691c0-125.921-102.0791-228-228-228S94,565.079,94,691a226.92219,226.92219,0,0,0,32.28333,117Z" transform="translate(-94 -53)" fill="#3f3d56" />
    <circle cx="228" cy="623" r="22" fill={ fill } />
    <circle cx="53" cy="659" r="11" fill={ fill } />
    <circle cx="59.45435" cy="563.72636" r="11" fill={ fill } />
    <circle cx="114.05636" cy="485.38515" r="11" fill={ fill } />
    <circle cx="201.20813" cy="446.35574" r="11" fill={ fill } />
    <circle cx="296.01341" cy="457.78748" r="11" fill={ fill } />
    <circle cx="371.38961" cy="516.41472" r="11" fill={ fill } />
    <circle cx="405.80436" cy="605.48969" r="11" fill={ fill } />
    <circle cx="389.42656" cy="699.56675" r="11" fill={ fill } />
    <line x1="228" y1="623" x2="341" y2="537" fill="none" stroke={ fill } strokeMiterlimit="10" strokeWidth="4" />
    <circle cx="332.54207" cy="198.352" r="14.75556" fill="none" stroke="#3f3d56" strokeMiterlimit="10" strokeWidth="2" />
    <circle cx="416.54207" cy="370.352" r="14.75556" fill="#3f3d56" />
    <circle cx="168.22291" cy="331.16552" r="42.77709" fill="#f2f2f2" />
</svg>
);

export default SpeedTestIcon;
