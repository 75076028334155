import React from "react";

import { Icon } from '@types';

const PersonalWebsiteIcon: Icon = ({ fill = '#c4c4c4', title = 'personal_website', ...props }) => (
<svg { ...props } id="a8771333-170f-4b31-b00f-586a01436e20" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="825.31769" height="679.90345" viewBox="0 0 825.31769 679.90345">
    <title>{ title }</title>
    <circle cx="670.82111" cy="368.90424" r="83.97916" fill="#ff6584" />
    <rect x="0.00001" width="825.31765" height="26.25696" fill="#f2f2f2" />
    <circle cx="22.08524" cy="12.74148" r="6.79546" fill={ fill } />
    <circle cx="44.17047" cy="12.74148" r="6.79546" fill={ fill } />
    <circle cx="66.2557" cy="12.74148" r="6.79546" fill={ fill } />
    <polygon points="0.584 502.277 0 502.779 0 679.903 825.318 679.903 825.318 368.779 815.482 369.525 0.584 502.277" fill="#f2f2f2" />
    <rect x="59.56143" y="262.64069" width="230.47032" height="9.29316" fill="#3f3d56" />
    <rect x="59.56143" y="283.08564" width="230.47032" height="9.29316" fill="#3f3d56" />
    <rect x="59.56143" y="303.53058" width="78.06253" height="9.29316" fill="#3f3d56" />
    <circle cx="386.36803" cy="662.26922" r="9.0658" fill="#fff" />
    <circle cx="412.65883" cy="663.1758" r="9.0658" fill="#f2f2f2" />
    <circle cx="438.94964" cy="664.08238" r="9.0658" fill="#fff" />
    <circle cx="412.74196" cy="663.1758" r="7.25264" fill="#3f3d56" />
    <path d="M859.14309,567.14468c0,59.16833-64.7533,37.4968-144.63055,37.4968S569.882,626.313,569.882,567.14468,634.6353,390.374,714.51254,390.374,859.14309,507.97634,859.14309,567.14468Z" transform="translate(-187.34116 -110.04827)" fill={ fill } />
    <path d="M1003.37369,616.762q-25.12823-4.25715-48.33186-7.31209l6.11529-11.85669c-2.06208-.71618-11.12175,6.0738-11.12175,6.0738l8.02528-34.41712c-10.37106,1.25072-15.644,36.39264-15.644,36.39264L930.8296,593.76134l5.60966,13.35766a1069.83378,1069.83378,0,0,0-122.62463-7.71735l5.23409-10.14812c-2.06208-.71618-11.1218,6.0738-11.1218,6.0738l8.02533-34.41712c-10.371,1.25072-15.644,36.39265-15.644,36.39265L788.7212,585.42161l5.9415,14.148c-13.45009.269-25.72688.84335-36.857,1.60468l1.84694-3.581c-1.308-.45428-5.42944,2.11049-8.27034,4.04784-.81884.06378-1.61951.13-2.42467.19572l7.59854-32.58688c-8.46015,1.02025-13.52718,24.5907-15.1074,33.24276-1.11305.10394-2.21655.2089-3.30028.316l-8.74246-8.9644c7.32342-11.36346,18.0432-20.33089,18.0432-20.33089-13.73443,4.08483-20.93885,10.92983-24.7034,17.31133a277.19745,277.19745,0,0,1,16.022-95.77534s-28.38856,61.776-24.76623,103.24263l.38774,6.5893c-38.7225-3.89644-72.76886-5.41622-102.0792-5.47969l5.23408-10.14812c-2.06208-.71618-11.12179,6.0738-11.12179,6.0738l8.02533-34.41712c-10.371,1.25072-15.644,36.39265-15.644,36.39265L587.2167,585.42161l5.94149,14.148a688.81886,688.81886,0,0,0-70.49169,4.78821c5.294-15.80886,23.27821-30.84435,23.27821-30.84435-13.73443,4.08483-20.93886,10.92983-24.70341,17.31133a277.19727,277.19727,0,0,1,16.022-95.77534s-28.38857,61.776-24.76623,103.24263l.43326,7.3633c-24.06708,3.44607-36.16695,7.03142-36.16695,7.03142L801.86914,616.762q-8.50438-1.44076-16.782-2.73627ZM729.378,593.88731l3.95519,9.418c-3.18683.34107-6.25122.69255-9.16219,1.05243A47.72894,47.72894,0,0,1,729.378,593.88731Z" transform="translate(-187.34116 -110.04827)" fill="#3f3d56" />
    <path d="M905.16965,608.10151c-.17562-.287-4.31644-7.201-5.752-21.55827-1.31676-13.172-.47006-35.37447,11.04443-66.34505,21.81385-58.67172-5.02713-106.01129-5.30139-106.48284l1.32423-.76823c.0695.11957,6.99553,12.1893,11.087,31.40851a137.02486,137.02486,0,0,1-5.675,76.37613c-21.77648,58.57158-5.58685,86.29886-5.42095,86.57237Z" transform="translate(-187.34116 -110.04827)" fill="#3f3d56" />
    <circle cx="709.29875" cy="290.27386" r="9.94813" fill={ fill } />
    <circle cx="740.67363" cy="327.00543" r="9.94813" fill="#3f3d56" />
    <circle cx="719.24688" cy="351.49314" r="9.94813" fill={ fill } />
    <circle cx="745.26507" cy="372.15465" r="9.94813" fill={ fill } />
    <circle cx="711.59447" cy="404.29477" r="9.94813" fill="#3f3d56" />
    <path d="M911.17948,608.46769s-9.94813-24.48771,19.89627-42.8535Z" transform="translate(-187.34116 -110.04827)" fill="#3f3d56" />
    <path d="M898.94482,608.02349s-4.52748-26.04064-39.56946-25.81745Z" transform="translate(-187.34116 -110.04827)" fill="#3f3d56" />
    <path d="M738.06824,183.40231c-10.91222-18.43827-32.50116-19.29757-32.50116-19.29757s-21.0372-2.69022-34.53243,25.39149c-12.57864,26.17446-29.93875,51.44643-2.79484,57.57369l4.903-15.26027,3.03639,16.39637a106.20415,106.20415,0,0,0,11.61387.19849c29.06893-.93851,56.7527.27459,55.86132-10.15657C742.46944,224.3812,748.568,201.14361,738.06824,183.40231Z" transform="translate(-187.34116 -110.04827)" fill="#2f2e41" />
    <polygon points="472.074 222.167 469.778 263.49 537.12 270.378 539.415 227.524 516.458 212.985 472.074 222.167" fill="#a0616a" />
    <path d="M840.39469,422.89675c-2.0195-19.52168-82.19232-28.38305-101.45987-30.23328l-7.20435-4.20257-2.29573-24.48771s-71.16741-6.12193-76.5241-6.88717-9.94813,9.18289-9.94813,11.47862c0,1.65915-1.00111,13.5075-5.02171,20.53016-8.68243,6.97666-35.15344,28.92858-35.15344,37.24553,0,9.94813,21.04412,135.065,21.04412,135.065l2.29573,26.01819,21.42675,3.82621-1.53049-81.11555s7.65241-46.6797-3.061-59.6888v-5.35668s31.42257-8.18664,42.49722-17.10032c3.209-.68958,12.22685-2.38844,17.20732-.911.683.40219,1.47051.81526,2.34588,1.23548a3.57764,3.57764,0,0,1,.31672.32316s.03129-.04815.08641-.13227c14.32632,6.70557,51.07713,15.16353,66.10694,12.75875a52.60028,52.60028,0,0,1,33.6706,6.12193c-8.41765,10.71337-2.29572,41.323-2.29572,41.323l.76524,65.81073,10.71337,13.0091h14.53958V531.561S842.69041,445.08874,840.39469,422.89675Z" transform="translate(-187.34116 -110.04827)" fill="#2f2e41" />
    <circle cx="511.48395" cy="98.58099" r="25.25295" fill="#a0616a" />
    <path d="M683.52028,224.69933s3.82621,16.8353-1.53048,18.36578a72.42051,72.42051,0,0,0-12.24386,5.35669L663.624,268.31806v19.89627l74.22837,8.41765,2.29573-6.88717v-9.94813l-8.41765-15.30482-3.82621-3.82621s-16.8353-18.36578-13.0091-35.96632Z" transform="translate(-187.34116 -110.04827)" fill="#a0616a" />
    <path d="M749.71363,285.536s2.29572,26.78343,0,39.02729-1.53048,30.60964-1.53048,30.60964l-16.8353-3.061v-41.323l3.8262-22.192Z" transform="translate(-187.34116 -110.04827)" fill="#a0616a" />
    <circle cx="520.86818" cy="54.14985" r="15.60999" fill="#2f2e41" />
    <path d="M690.95619,160.09023A15.61059,15.61059,0,0,1,704.923,144.568a15.61,15.61,0,1,0,0,31.04447A15.6106,15.6106,0,0,1,690.95619,160.09023Z" transform="translate(-187.34116 -110.04827)" fill="#2f2e41" />
    <polygon points="545.903 71.577 524.574 60.404 495.12 64.975 489.026 91.89 504.195 91.306 508.433 81.418 508.433 91.143 515.433 90.874 519.496 75.131 522.035 91.89 546.918 91.382 545.903 71.577" fill="#2f2e41" />
    <rect x="468.24783" y="270.3776" width="93.3594" height="93.3594" fill="#f2f2f2" />
    <path d="M640.28417,272.52689l-13.77434,56.62783s-5.35669,12.24386,6.88717,19.89627,39.79253,17.60054,39.79253,17.60054,24.48771,33.67061,27.54868,19.89627-18.36579-31.37488-18.36579-31.37488l-37.49681-23.72247,14.53958-39.79254Z" transform="translate(-187.34116 -110.04827)" fill="#a0616a" />
    <path d="M739.7655,343.6943s11.47861-1.53048,10.71337,11.47862-16.07006,14.53958-16.07006,14.53958-6.88717,29.07915-16.8353,23.72247,5.35668-34.43585,5.35668-34.43585l13.0091-9.94813Z" transform="translate(-187.34116 -110.04827)" fill="#a0616a" />
    <path d="M665.91974,267.55282l12.24386-25.25295-24.48772,8.41765s-13.77433,6.88717-14.53958,22.95723,17.60055,24.48771,17.60055,24.48771,3.061,22.95723,1.53048,26.0182,0,15.30482,0,15.30482,29.46178-8.035,44.00136,1.9131,26.40081,4.20882,26.40081,4.20882-.76524-19.131,5.35669-22.192,11.47861-17.60055,6.12193-26.78344c0,0,12.24385,1.53048,11.47861-9.94813s-6.88717-21.42675-16.8353-26.0182-11.04785-5.325-11.04785-5.325l14.10881,25.98654v6.88717s-24.97661-15.94252-49.68752-17.27106a32.91358,32.91358,0,0,0-22.24467,7.32256l-.00046.00037Z" transform="translate(-187.34116 -110.04827)" fill="#2f2e41" />
    <path d="M646.36342,561.72557s10.85884,5.11,14.05262,7.0263,15.96888-1.91626,13.41385,9.58133S660.416,600.68963,660.416,600.68963s-5.11005,5.7488-5.11005,9.58133-12.7751,33.21527-28.10523,31.93776-12.7751-16.60763-12.7751-16.60763l3.19378-16.60764s6.38755-28.10523,7.66506-27.46647,4.47129,5.11,4.47129,5.11S645.72467,580.24947,646.36342,561.72557Z" transform="translate(-187.34116 -110.04827)" fill="#d0cde1" />
    <path d="M805.6189,542.59454s-10.85884,5.11-14.05262,7.02631-15.96888-1.91627-13.41386,9.58133,13.41386,22.35643,13.41386,22.35643,5.11,5.7488,5.11,9.58133,12.77511,33.21527,28.10523,31.93776,12.77511-16.60764,12.77511-16.60764l-3.19378-16.60763s-6.38755-28.10523-7.66506-27.46648-4.47129,5.11005-4.47129,5.11005S806.25765,561.11844,805.6189,542.59454Z" transform="translate(-187.34116 -110.04827)" fill="#d0cde1" />
</svg>
);

export default PersonalWebsiteIcon;
