import React from 'react';

// import styled from '@emotion/styled';
// import mediaqueries from '@styles/media';

import SectionTemplate from '@types';

import LandingTechnologies from '@sections/landing/Landing.Technologies';

const ServiceTechnologies: SectionTemplate = ({ sectionContext }) => {
    return <LandingTechnologies sectionContext={sectionContext} />;
};

export default ServiceTechnologies;
