import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Paragraph from '@components/Paragraph';
import GridBase from '@components/Grid/Grid.Base';
import CardDefault from '@components/Card/Card.Default';
import CardContent from '@components/Card/Card.Content';

import SectionTemplate from '@types';

import SpotifyLogo from '@images/logos/Spotify_logo_with_text.svg';
import NetflixLogo from '@images/logos/netflix_logo.svg';
import DropboxLogo from '@images/logos/Dropbox_logo_2017.svg';
import UberLogo from '@images/logos/Uber_logo_2018.svg';
import YoutubeLogo from '@images/logos/YouTube_Logo_2017.svg';



const LandingTechnologies: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();

    return (
        <Section {...sectionContext}>
            <Wrapper>
                <StyledCardDefault>
                    <StyledCardContent>
                        <StyledGrid>
                            <StyledCardElement>
                                <StyledParagraph className="opacity-60">{intl.formatMessage({ id: 'brands-title' })}</StyledParagraph>
                            </StyledCardElement>
                            <StyledCardElement className="spotify">
                                <SpotifyLogo />
                            </StyledCardElement>
                            <StyledCardElement className="uber">
                                <UberLogo />
                            </StyledCardElement>
                            <StyledCardElement className="dropbox">
                                <DropboxLogo />
                            </StyledCardElement>
                            <StyledCardElement className="netflix">
                                <NetflixLogo />
                            </StyledCardElement>
                            <StyledCardElement className="youtube">
                                <YoutubeLogo />
                            </StyledCardElement>
                        </StyledGrid>
                    </StyledCardContent>
                </StyledCardDefault>
            </Wrapper>
        </Section>
    );
};

const StyledParagraph = styled(Paragraph)`
    ${mediaqueries.from_tablet`
        font-size: 18px;
    `};
`;

const StyledCardContent = styled(CardContent)`
    padding: 7% 5%;
    padding: 0px;

    ${mediaqueries.from_tablet`
        padding: 0px;
    `};
`;

const StyledCardDefault = styled(CardDefault)`
    box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 25px 0px;
    padding: 10px;
    // Mimicking a <CardContainer>
    max-width: 550px;
    width: 94%;
    
    ${mediaqueries.from_tablet`
        padding: 30px 20px;
    `};

    ${mediaqueries.from_tablet_ipad`
        // Mimicking a <CardContainer>
        max-width: unset;
        width: 100%;
    `};

    ${mediaqueries.from_laptop`
        padding: 40px 30px;
    `};
`;

const StyledGrid = styled(GridBase)`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    grid-auto-rows: 1fr;
    -webkit-box-align: center;
    align-items: center;
    justify-items: center;

    ${mediaqueries.from_tablet_ipad`
        grid-template-columns: repeat(3, 1fr);
    `};

    ${mediaqueries.from_desktop`
        grid-template-columns: repeat(6, 1fr);
    `};
`;

const StyledCardElement = styled(CardContent)`
    max-width: 200px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;

    & img, svg {
        width: 100%;
        height: auto;
        max-width: 130px;
        max-height: 39px; // Because of UBER logo
        object-fit: contain;
        display: block;
        
        & path {
            transition: all 0.2s ease 0s;
            fill: #B3B3B3 !important;

        }
    }

    & p {
        margin-bottom: 0px;
    }

    ${mediaqueries.from_mobile_i6`
        padding: 14px 22px;
    `};

    ${mediaqueries.from_tablet_ipad`
        padding: 10px 0;
    `};

    &:hover {
        &.spotify path {
            fill: #1ED760 !important;
        }

        &.uber path {
            fill: #010202 !important;
        }

        &.netflix path {
            fill: #d81f26 !important;
        }

        &.dropbox path {
            fill: #000000 !important;

            &.st3 {
                fill: #0061FF !important;
            }

            &.st6 {
                fill: none !important;
            }
        }

        &.youtube path {
            &.st0 {
                fill:#FF0000 !important;
            }
            &.st1 {
                fill:#FFFFFF !important;
            }
            &.st2 {
                fill:#282828 !important;
            }
        }
    }
`;

export default LandingTechnologies;