import React from 'react';

// import styled from '@emotion/styled';
// import mediaqueries from '@styles/media';

import SectionTemplate from '@types';

import LandingCTA from '@sections/landing/Landing.CTA';

const ServiceCTA: SectionTemplate = ({ sectionContext }) => {
    return <LandingCTA sectionContext={sectionContext} />;
};

export default ServiceCTA;
