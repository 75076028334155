import React from "react";

import { Icon } from '@types';

const ExperienceDesignIcon: Icon = ({ fill = '#c4c4c4', title = 'experience design', ...props }) => (
<svg { ...props } id="ab0c8d57-73b5-4efc-a896-a2305a232bd8" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="1027.4958" height="696.05876" viewBox="0 0 1027.4958 696.05876">
    <title>{ title }</title>
    <circle cx="696.2479" cy="355.70168" r="76.7521" fill={ fill } opacity="0.4" />
    <rect x="270" y="461.90193" width="428" height="116" fill="#3f3d56" />
    <path d="M756.83869,276.47654l-17.08989,8.08834,15.1253-11.3099a85.35244,85.35244,0,0,0-8.25839-10.86725L672.499,280.80982l66.94832-25.53848a85.33888,85.33888,0,0,0-9.72181-7.41015L616.789,266.0523a84.63924,84.63924,0,0,0-18.53688,52.82025h170A84.59367,84.59367,0,0,0,756.83869,276.47654Z" transform="translate(-86.2521 -101.97062)" fill="#f2f2f2" />
    <path d="M770.37527,152.166A495.97179,495.97179,0,0,0,552.2521,101.97062c-153.82965,0-291.36139,69.75269-382.71033,179.34869C325.96817,247.04484,598.319,187.71378,770.37527,152.166Z" transform="translate(-86.2521 -101.97062)" fill="#f2f2f2" />
    <rect y="693.90193" width="1020" height="2" fill="#2f2e41" />
    <path d="M682.11649,318.87255,624.2521,232.92193s87.63109,30.59259,77.68592,85.95062Z" transform="translate(-86.2521 -101.97062)" fill="#3f3d56" />
    <path d="M684.38771,318.87255l57.86439-85.95062s-87.63109,30.59259-77.68591,85.95062Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <path d="M784.7521,797.87255h-429v-640h429Zm-427-2h425v-636h-425Z" transform="translate(-86.2521 -101.97062)" fill="#2f2e41" />
    <rect x="271" y="71.08308" width="427" height="2" fill="#2f2e41" />
    <circle cx="282" cy="63.90193" r="4" fill={ fill } />
    <circle cx="293" cy="63.90193" r="4" fill={ fill } />
    <circle cx="304" cy="63.90193" r="4" fill={ fill } />
    <rect x="286" y="84.90193" width="18" height="10" fill={ fill } />
    <rect x="286" y="163.90193" width="88" height="10" fill={ fill } />
    <rect x="685.2521" y="186.87255" width="88" height="10" transform="translate(1372.2521 281.77448) rotate(-180)" fill="#f2f2f2" />
    <rect x="286" y="180.90193" width="148" height="6" fill="#f2f2f2" />
    <rect x="286" y="193.90193" width="140" height="6" fill="#f2f2f2" />
    <rect x="286" y="206.90193" width="102" height="6" fill="#f2f2f2" />
    <rect x="320" y="384.90193" width="58" height="6" fill="#f2f2f2" />
    <rect x="320" y="397.90193" width="54.86486" height="6" fill="#f2f2f2" />
    <rect x="320" y="410.90193" width="39.97297" height="6" fill="#f2f2f2" />
    <rect x="455" y="384.90193" width="58" height="6" fill="#f2f2f2" />
    <rect x="455" y="397.90193" width="54.86486" height="6" fill="#f2f2f2" />
    <rect x="455" y="410.90193" width="39.97297" height="6" fill="#f2f2f2" />
    <rect x="595" y="384.90193" width="58" height="6" fill="#f2f2f2" />
    <rect x="595" y="397.90193" width="54.86486" height="6" fill="#f2f2f2" />
    <rect x="595" y="410.90193" width="39.97297" height="6" fill="#f2f2f2" />
    <rect x="410" y="611.90193" width="88" height="10" fill={ fill } />
    <rect x="410" y="628.90193" width="148" height="6" fill="#f2f2f2" />
    <rect x="410" y="641.90193" width="140" height="6" fill="#f2f2f2" />
    <rect x="410" y="654.90193" width="102" height="6" fill="#f2f2f2" />
    <rect x="312.5" y="271.90193" width="348" height="2" fill={ fill } opacity="0.3" />
    <rect x="312" y="306.90193" width="74" height="66" fill={ fill } opacity="0.3" />
    <rect x="447" y="306.90193" width="74" height="66" fill="#3f3d56" />
    <rect x="587" y="306.90193" width="74" height="66" fill="#3f3d56" />
    <path d="M560.61073,474.59512l-.97627-.01907c.021-1.08114.63114-26.56133,10.66219-33.604l.56059.79893C561.23091,448.52955,560.6155,474.33532,560.61073,474.59512Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <circle cx="489.20618" cy="333.79786" r="4.88134" fill={ fill } />
    <path d="M574.47138,453.00423a15.19766,15.19766,0,0,1-7.84149.33859,13.85019,13.85019,0,0,1,14.23985-4.88441A15.19761,15.19761,0,0,1,574.47138,453.00423Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <path d="M700.61073,474.59512l-.97627-.01907c.021-1.08114.63114-26.56133,10.66219-33.604l.56059.79893C701.23091,448.52955,700.6155,474.33532,700.61073,474.59512Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <circle cx="629.20618" cy="333.79786" r="4.88134" fill={ fill } />
    <path d="M714.47138,453.00423a15.19766,15.19766,0,0,1-7.84149.33859,13.85019,13.85019,0,0,1,14.23985-4.88441A15.19761,15.19761,0,0,1,714.47138,453.00423Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <rect x="286" y="498.90193" width="88" height="10" fill={ fill } />
    <rect x="286" y="515.90193" width="148" height="6" fill="#f2f2f2" />
    <rect x="286" y="528.90193" width="140" height="6" fill="#f2f2f2" />
    <rect x="286" y="541.90193" width="102" height="6" fill="#f2f2f2" />
    <circle cx="611.7521" cy="512.70168" r="31.2479" fill={ fill } opacity="0.4" />
    <circle cx="553.92017" cy="527.62605" r="21.92017" fill={ fill } opacity="0.4" />
    <circle cx="167.5" cy="582.40193" r="79" fill="#2f2e41" />
    <rect x="131.5" y="644.40193" width="24" height="43" fill="#2f2e41" />
    <rect x="179.5" y="644.40193" width="24" height="43" fill="#2f2e41" />
    <ellipse cx="151.5" cy="687.90193" rx="20" ry="7.5" fill="#2f2e41" />
    <ellipse cx="199.5" cy="686.90193" rx="20" ry="7.5" fill="#2f2e41" />
    <circle cx="169.5" cy="562.40193" r="27" fill="#fff" />
    <circle cx="169.5" cy="550.40193" r="9" fill="#3f3d56" />
    <path d="M177.11943,610.90483c-6.37889-28.56758,14.01185-57.43392,45.544-64.47477s62.2651,10.41,68.644,38.9776-14.51861,39.10379-46.05075,46.14464S183.49832,639.4724,177.11943,610.90483Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <ellipse cx="313.52848" cy="596.95692" rx="39.5" ry="12.40027" transform="translate(-446.20984 527.75787) rotate(-65.07683)" fill="#2f2e41" />
    <ellipse cx="168.52848" cy="620.95692" rx="12.40027" ry="39.5" transform="translate(-357.17251 49.01394) rotate(-27.89329)" fill="#2f2e41" />
    <ellipse cx="906.25518" cy="625.62283" rx="12.40027" ry="39.5" transform="translate(-250.61562 265.81898) rotate(-21.07832)" fill="#2f2e41" />
    <circle cx="893.18955" cy="583.10381" r="79" fill="#2f2e41" />
    <rect x="991.67544" y="747.00687" width="24" height="43" transform="matrix(-1, 0.0028, -0.0028, -1, 1923.24743, 1432.22882)" fill="#2f2e41" />
    <rect x="943.67563" y="747.14132" width="24" height="43" transform="translate(1827.24837 1432.63217) rotate(179.83951)" fill="#2f2e41" />
    <ellipse cx="995.7371" cy="790.5292" rx="20" ry="7.5" transform="translate(-88.46246 -99.17847) rotate(-0.16049)" fill="#2f2e41" />
    <ellipse cx="947.73448" cy="789.66365" rx="20" ry="7.5" transform="translate(-88.46023 -99.31292) rotate(-0.16049)" fill="#2f2e41" />
    <circle cx="891.13354" cy="563.10949" r="27" fill="#fff" />
    <circle cx="891.13354" cy="563.10949" r="9" fill="#3f3d56" />
    <path d="M1055.86824,611.39234c6.29884-28.58533-14.17267-57.39444-45.72441-64.34694s-62.23569,10.58438-68.53454,39.16971,14.62808,39.063,46.17982,46.01548S1049.56939,639.97768,1055.86824,611.39234Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <ellipse cx="1068.31896" cy="648.16898" rx="39.5" ry="12.40027" transform="translate(-261.30169 421.84772) rotate(-25.53475)" fill="#2f2e41" />
    <path d="M1018.32588,713.46562c.02166,7.732-19.84411,23.05568-41.93541,23.11755s-42.86536-21.648-42.887-29.38,20.71728,1.442,42.80858,1.3801S1018.30423,705.73367,1018.32588,713.46562Z" transform="translate(-86.2521 -101.97062)" fill="#fff" />
    <circle cx="917.2479" cy="468.70168" r="110.2479" fill={ fill } opacity="0.4" />
    <circle cx="190.40483" cy="348.59022" r="79" fill="#2f2e41" />
    <rect x="226.16663" y="506.95016" width="24" height="43" transform="translate(11.69174 -135.94422) rotate(10.26106)" fill="#2f2e41" />
    <rect x="273.39893" y="515.50057" width="24" height="43" transform="translate(13.97028 -144.22112) rotate(10.26106)" fill="#2f2e41" />
    <ellipse cx="285.68749" cy="563.4754" rx="7.5" ry="20" transform="translate(-415.18867 315.35713) rotate(-49.73894)" fill="#2f2e41" />
    <ellipse cx="239.4392" cy="555.10313" rx="7.5" ry="20" transform="translate(-425.15907 277.10318) rotate(-49.73894)" fill="#2f2e41" />
    <circle cx="195.93551" cy="329.26636" r="27" fill="#fff" />
    <circle cx="207.03243" cy="319.5883" r="9" fill="#3f3d56" />
    <path d="M214.337,364.61728c-1.188-29.247,24.01865-54.01935,56.30068-55.33066s59.41489,21.335,60.60291,50.582-21.2521,35.89214-53.53414,37.20345S215.525,393.86426,214.337,364.61728Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <ellipse cx="349.6025" cy="436.16834" rx="39.5" ry="12.40027" transform="translate(-174.86437 -12.8362) rotate(-12.9101)" fill="#2f2e41" />
    <ellipse cx="194.52848" cy="461.95692" rx="39.5" ry="12.40027" transform="translate(-184.5461 -46.83125) rotate(-12.9101)" fill="#2f2e41" />
    <path d="M254.95954,481.69373a18,18,0,0,0,35.42423,6.41281c1.77085-9.78213-5.79238-13.24363-15.57451-15.01448S256.73039,471.9116,254.95954,481.69373Z" transform="translate(-86.2521 -101.97062)" fill="#fff" />
    <rect x="380.7521" y="374.37255" width="74" height="66" transform="translate(48.33035 -204.77859) rotate(16.68876)" fill="#3f3d56" />
    <path d="M399.11981,435.94807l-.92967-.29863c.33056-1.02957,8.23224-25.26129,19.86323-29.12666l.30755.92627C407.19918,411.15851,399.199,435.70058,399.11981,435.94807Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
    <circle cx="338.23982" cy="301.05004" r="4.88134" fill={ fill } />
    <path d="M418.59694,419.247a15.19762,15.19762,0,0,1-7.60842-1.92753,13.85017,13.85017,0,0,1,15.04271-.58938A15.19758,15.19758,0,0,1,418.59694,419.247Z" transform="translate(-86.2521 -101.97062)" fill={ fill } />
</svg>
);

export default ExperienceDesignIcon;
