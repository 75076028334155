import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import GridCustom from '@components/Grid/Grid.Custom';
import HeroWrapper from '@components/Hero/Hero.Wrapper';
import HeroContent from '@components/Hero/Hero.Content';
import Link from '@components/Link';
import LinkContainer from '@components/Link/Link.Container';

import TranslatedSectionTemplate from '@types';

import Image from '../../components/Image';
import SVGBackground from '@misc/hero-background.svg';

/**
 * This is a template for all Service Heroes
*/

const ServiceHero: TranslatedSectionTemplate = ({ sectionContext, translations }) => {
    const intl = useIntl();

    const MainHeroIcon = (translations && translations.MainIcon) ?
        styled(translations.MainIcon.Icon)`
            // width: 100%;
            height: auto;
            z-index: -1;

            ${mediaqueries.laptop`
                width: 70%;
            `}

            ${mediaqueries.from_laptop`
                width: 100%;
            `}
        ` : styled.div``;

    return (

        <Transitions.CSS.FadeIn>
            <Section hero gradientBg {...sectionContext}>
                <HeroBackground />

                <Wrapper zIndex="1">
                    <StyledHeroWrapper>
                        <StyledGridCustom columns="6fr 4fr" rows="auto" columnGap="30px" rowGap="0">
                            <HeroContent>
                                <Transitions.CSS.FadeInUp delay="0.1">
                                    <Heading className="page-h2">{ translations.title.text }</Heading>
                                </Transitions.CSS.FadeInUp>
                                { translations.subtitle &&
                                <Transitions.CSS.FadeInUp delay="0.2">
                                    <Headings.h3>{ translations.subtitle.text }</Headings.h3>
                                </Transitions.CSS.FadeInUp>
                                }
                                { translations.description &&
                                <Transitions.CSS.FadeInUp delay="0.3">
                                    <HeroParagraph>{ translations.description.text }</HeroParagraph>
                                </Transitions.CSS.FadeInUp>
                                }
                                { (translations.Link1 || translations.Link2) && 
                                <Transitions.CSS.FadeInUp delay="0.4">
                                    <StyledLinkContainer>
                                    { translations.Link1 &&
                                        <Link to={ translations.Link1.link } className="button button--dark" data-scroll>{ translations.Link1.text }</Link>
                                    }
                                    { translations.Link2 &&
                                        <Link to={ translations.Link2.link } className="button button--white">{ translations.Link2.text }</Link>
                                    }
                                    </StyledLinkContainer>
                                </Transitions.CSS.FadeInUp>
                                }
                            </HeroContent>
                            { translations.MainIcon && 
                            <Transitions.CSS.FadeInUp delay="0.5">
                                <StyledHeroContent>
                                    <MainHeroIcon fill={translations.MainIcon.fill ? translations.MainIcon.fill : '#c4c4c4'} />
                                </StyledHeroContent>
                            </Transitions.CSS.FadeInUp>
                            }
                        </StyledGridCustom>
                    </StyledHeroWrapper>
                </Wrapper>
            </Section>
        </Transitions.CSS.FadeIn>
    );
};


const StyledHeroWrapper = styled(HeroWrapper)`
    ${mediaqueries.laptop`
        max-width: 500px;
        margin-bottom: 80px;
    `}

    ${mediaqueries.tablet`
        text-align: left;
        margin-bottom: 60px;
        max-width: 400px;
    `}
    
`;

const Heading = styled(Headings.h1)`
    font-weight: 800;
`;

const HeroParagraph = styled(Paragraph)`
    margin-left: 0;
    margin-right: 0;
    text-align: left;
    ${mediaqueries.from_tablet_ipad`
        font-size: 18px;
    `}
`;

const HeroBackground = styled(SVGBackground)`
    position: absolute;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;

    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%) matrix(-1, 0, 0, 1, 0, 0);
    min-width: 1000px !important;
`;

const StyledGridCustom = styled(GridCustom)`
    ${mediaqueries.laptop`
        grid-template-columns: 1fr;
        align-content: center;
    `}
`;

const StyledHeroContent = styled(HeroContent)`
    max-width: 100%;
    margin-top: 40px;

    ${mediaqueries.from_laptop`
        margin-top: 0;
        min-width: 350px;
        align-content: center;
        justify-content: center;
        // align-self: center;
        // justify-self: center;
    `}
`;

const StyledLinkContainer = styled(LinkContainer)`
    ${mediaqueries.from_laptop`
        margin-bottom: 0px;
    `}
`;

export default ServiceHero;