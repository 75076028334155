import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import GridBase from '@components/Grid/Grid.Base';
import CardDefault from '@components/Card/Card.Default';
import CardContent from '@components/Card/Card.Content';

import Typeform from '../../components/Typeform';
import Image from '../../components/Image';

import FloatingBackground from '@images/cta-background.svg';

import SectionTemplate from '@types';


const LandingCTA: SectionTemplate = ({ sectionContext }) => {
    const intl = useIntl();

    return (
        <Section {...sectionContext}>
            <Wrapper>
                <StyledCardDefault>
                    <StyledGrid>
                        <StyledInnerElement>
                            <StyledInnerText>
                                <Headings.h3>{intl.formatMessage({ id: 'floating-cta-title' })}</Headings.h3>
                                <Paragraph>{intl.formatMessage({ id: 'floating-cta-description' })}</Paragraph>
                                <Typeform url={intl.formatMessage({ id: 'typeform-evaluate-url' })}>
                                {intl.formatMessage({ id: 'floating-cta-button' })}                                        
                                </Typeform>
                            </StyledInnerText>
                        </StyledInnerElement>
                       <StyledInnerImage>
                            <Image imgName="website-evaluation.png" style={{ height: '100%' }} />
                        </StyledInnerImage>
                    </StyledGrid>
                </StyledCardDefault>
            </Wrapper>
        </Section>
    );
};


const StyledCardDefault = styled(CardDefault)`
    box-shadow: none;
    padding: 0;
    overflow: visible;
`;

const StyledGrid = styled(GridBase)`
    grid-template-columns: 40% 60%;
    grid-template-rows: 100px auto 100px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    display: block;
    width: 100%;
    min-height: 100%;


    ${mediaqueries.from_laptop`
        display: grid;
    `};
`;

const StyledInnerElement = styled(CardContent)`
    width: 100%;
    min-height: 100%;
    background-color: rgb(255, 255, 255);
    width: 100%;

    padding: 24px !important;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 25px 0px;
    border-radius: 0.25em;
    background-position: center center;
    background-size: cover;
    background-image: url(${FloatingBackground});

    ${mediaqueries.from_laptop`
        padding: 48px !important;

        grid-row-end: 3;
        grid-row-start: 2;

        grid-column-start: 1;
        grid-column-end: 3;
    `};
`;

const StyledInnerText = styled(CardContent)`
    width: 100%;
    margin: 0;
    padding: 0 !important;

    & h3, & p {
        color: white;
    }

    ${mediaqueries.from_laptop`
        padding: 0;
        width: 39%;

        & p {
            font-size: 18px;
        }
    `};
`;

const StyledInnerImage = styled(CardContent)`
    display: none;
    margin: 0;
    padding: 0 !important;

    ${mediaqueries.from_laptop`
        grid-row-start: 1;
        grid-row-end: 4;

        grid-column-start: 2;
        grid-column-end: 3;
    `};

    ${mediaqueries.from_laptop`
        display: block;
        position: relative;
        height: auto;
    `};
        // === Due to Gatsby-Image - these styles should apply only to img ===
        & img {
            // width: unset !important;
            height: auto !important;
            top: 50% !important;
            left: 50% !important;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            max-width: 100%;
            max-height: 100%;
        }

    ${mediaqueries.from_laptop`
        & picture {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            // top: 50%;
            // left: 50%;
            // -webkit-transform: translate(-50%,-50%);
            // -ms-transform: translate(-50%,-50%);
            // transform: translate(-50%,-50%);

            & img {
                position: absolute;
                max-height: 100%;
                max-width: 100%;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
            // === End of Gatsby-Image styles ===
        }
    `};
`;

export default LandingCTA;